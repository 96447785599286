import base from "@reflexjs/preset-base"

export default {
  preset: base,

  colors: {
    primary: "orange",
    secondary: "black",
    primaryHover: "black"
  }
}
